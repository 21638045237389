import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

const StyledPath = styled.path`
  stroke: ${getColor("blue", "500")};
`;

const AttachIcon = () => (
  <svg
    fill="none"
    height="12"
    viewBox="0 0 12 12"
    width="12"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      d="M10.72 5.52545L6.12502 10.1204C5.5621 10.6834 4.79861 10.9996 4.00252 10.9996C3.20643 10.9996 2.44294 10.6834 1.88002 10.1204C1.3171 9.55753 1.00085 8.79404 1.00085 7.99795C1.00085 7.20186 1.3171 6.43837 1.88002 5.87545L6.47502 1.28045C6.8503 0.905167 7.35929 0.694336 7.89002 0.694336C8.42075 0.694336 8.92974 0.905167 9.30502 1.28045C9.6803 1.65573 9.89114 2.16472 9.89114 2.69545C9.89114 3.22618 9.6803 3.73517 9.30502 4.11045L4.70502 8.70545C4.51738 8.89309 4.26289 8.9985 3.99752 8.9985C3.73216 8.9985 3.47766 8.89309 3.29002 8.70545C3.10238 8.51781 2.99697 8.26331 2.99697 7.99795C2.99697 7.73258 3.10238 7.47809 3.29002 7.29045L7.53502 3.05045"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AttachIcon;
