import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type EditIconVariant = "blue" | "white";

type StyledPathStyledProps = {
  $isDisabled?: boolean;
  $variant: EditIconVariant;
};

type Props = {
  height?: number;
  isDisabled?: boolean;
  size?: number;
  variant?: EditIconVariant;
};

const strokeColors = {
  ["blue"]: getColor("blue", "700"),
  ["white"]: getColor("gray", "000")
};

const StyledPath = styled.path<StyledPathStyledProps>`
  stroke: ${props =>
    props.$isDisabled ? getColor("gray", "100") : strokeColors[props.$variant]};
`;

const EditIcon = ({isDisabled, size, variant = "blue"}: Props) => (
  <svg fill="none" height={size} viewBox="0 0 20 20" width={size}>
    <StyledPath
      $isDisabled={isDisabled}
      $variant={variant}
      d="M10 16.666h7.5M13.75 2.916a1.768 1.768 0 012.5 2.5L5.833 15.833l-3.333.833.833-3.333L13.75 2.916z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
    />
  </svg>
);

export default EditIcon;
