const AvatarIcon = () => (
  <svg
    fill="none"
    height="40"
    viewBox="0 0 32 30"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="16" cy="8.85714" fill="white" opacity="0.5" r="6.85714" />
    <circle cx="16" cy="36.2857" fill="white" opacity="0.5" r="15.4286" />
  </svg>
);

export default AvatarIcon;
