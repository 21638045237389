import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

const WhitePath = styled.path`
  fill: ${getColor("gray", "000")};
`;

const LightBluePath = styled.path`
  fill: ${getColor("blue", "400")};
`;

const CircledExclamationIcon = () => (
  <svg
    fill="none"
    height="40"
    viewBox="0 0 40 40"
    width="40"
    xmlns="http://www.w3.org/2000/svg"
  >
    <LightBluePath d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" />
    <WhitePath d="M22.6455 10L22.1955 21.5736H17.8045L17.3682 10H22.6455ZM20 30C19.1727 30 18.4636 29.7119 17.8727 29.1358C17.2909 28.5596 17 27.8574 17 27.0292C17 26.2189 17.2909 25.5257 17.8727 24.9496C18.4636 24.3734 19.1727 24.0853 20 24.0853C20.8091 24.0853 21.5091 24.3734 22.1 24.9496C22.7 25.5257 23 26.2189 23 27.0292C23 27.5783 22.8591 28.078 22.5773 28.5281C22.3045 28.9782 21.9409 29.3383 21.4864 29.6084C21.0409 29.8695 20.5455 30 20 30Z" />
  </svg>
);

export default CircledExclamationIcon;
