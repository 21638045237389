import {getColorByAlias} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

const StyledPath = styled.path`
  stroke: ${getColorByAlias("accentSecondary")};
`;

const ArrowUpRightIcon = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      d="M4.6665 11.3334L11.3332 4.66675"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
    <StyledPath
      d="M4.6665 4.66675H11.3332V11.3334"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
  </svg>
);

export default ArrowUpRightIcon;
