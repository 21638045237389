import styled from "styled-components";

import type {GetColor, GetColorStyledProps} from "./types";

type Props = {
  className?: string;
  getColor: GetColor<GetColorStyledProps>;
  size: number;
};

const StyledPath = styled.path<GetColorStyledProps>`
  stroke: ${props => props.$getColor};
`;

const TrashIcon = ({getColor, size, ...rest}: Props) => (
  <svg fill="none" height={size} viewBox="0 0 16 16" {...rest}>
    <StyledPath
      $getColor={getColor}
      d="M2 4H3.33333H14"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
    <StyledPath
      $getColor={getColor}
      d="M12.6693 3.9987V13.332C12.6693 13.6857 12.5288 14.0248 12.2787 14.2748C12.0287 14.5249 11.6896 14.6654 11.3359 14.6654H4.66927C4.31565 14.6654 3.97651 14.5249 3.72646 14.2748C3.47641 14.0248 3.33594 13.6857 3.33594 13.332V3.9987M5.33594 3.9987V2.66536C5.33594 2.31174 5.47641 1.9726 5.72646 1.72256C5.97651 1.47251 6.31565 1.33203 6.66927 1.33203H9.33594C9.68956 1.33203 10.0287 1.47251 10.2787 1.72256C10.5288 1.9726 10.6693 2.31174 10.6693 2.66536V3.9987"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
  </svg>
);

export default TrashIcon;
