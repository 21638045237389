import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  size: number;
};

const StyledPath = styled.path`
  fill: ${getColor("green", "500")};
`;

const GreenCheckIcon = ({size, ...rest}: Props) => (
  <svg
    {...rest}
    fill="none"
    height={size}
    viewBox="0 0 24 24"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect fill="white" height="10" width="10" x="7" y="8" />
    <StyledPath
      clipRule="evenodd"
      d="M11.9994 21.5994C14.5455 21.5994 16.9873 20.588 18.7876 18.7876C20.588 16.9873 21.5994 14.5455 21.5994 11.9994C21.5994 9.45334 20.588 7.01154 18.7876 5.21119C16.9873 3.41084 14.5455 2.39941 11.9994 2.39941C9.45334 2.39941 7.01154 3.41084 5.21119 5.21119C3.41084 7.01154 2.39941 9.45334 2.39941 11.9994C2.39941 14.5455 3.41084 16.9873 5.21119 18.7876C7.01154 20.588 9.45334 21.5994 11.9994 21.5994ZM16.4478 10.4478C16.6664 10.2215 16.7874 9.91837 16.7846 9.60373C16.7819 9.2891 16.6557 8.98812 16.4332 8.76563C16.2107 8.54314 15.9097 8.41694 15.5951 8.41421C15.2805 8.41147 14.9773 8.53242 14.751 8.75101L10.7994 12.7026L9.24781 11.151C9.02149 10.9324 8.71837 10.8115 8.40373 10.8142C8.0891 10.8169 7.78812 10.9431 7.56563 11.1656C7.34314 11.3881 7.21694 11.6891 7.21421 12.0037C7.21147 12.3184 7.33242 12.6215 7.55101 12.8478L9.95101 15.2478C10.176 15.4728 10.4812 15.5992 10.7994 15.5992C11.1176 15.5992 11.4228 15.4728 11.6478 15.2478L16.4478 10.4478Z"
      fillRule="evenodd"
    />
  </svg>
);

export default GreenCheckIcon;
