import {getColorByAlias} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  size: number;
};

const StyledPath = styled.path`
  fill: ${getColorByAlias("accentSecondary")};
`;

const PencilIcon = ({size, ...rest}: Props) => (
  <svg
    {...rest}
    fill="none"
    height={size}
    viewBox="0 0 14 14"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      d="M10 1.33414C10.2652 1.06892 10.6249 0.919922 11 0.919922C11.1857 0.919922 11.3696 0.956502 11.5412 1.02757C11.7128 1.09864 11.8687 1.20281 12 1.33414C12.1313 1.46546 12.2355 1.62136 12.3066 1.79294C12.3776 1.96452 12.4142 2.14842 12.4142 2.33414C12.4142 2.51985 12.3776 2.70375 12.3066 2.87533C12.2355 3.04691 12.1313 3.20281 12 3.33414L3.66667 11.6675L1 12.3341L1.66667 9.66747L10 1.33414Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
);

export default PencilIcon;
