import styled from "styled-components";

import type {GetColor, GetColorStyledProps} from "./types";

type Props = {
  className?: string;
  getColor: GetColor<GetColorStyledProps>;
  size: number;
};

const StyledPath = styled.path<GetColorStyledProps>`
  stroke: ${props => props.$getColor};
`;

const StyledRect = styled.rect<GetColorStyledProps>`
  fill: ${props => props.$getColor};
`;

const MinusCircleIcon = ({getColor, size, ...rest}: Props) => (
  <svg fill="none" height={size} viewBox="0 0 16 16" width={size} {...rest}>
    <g clipPath="url(#clip0_8415_51183)">
      <StyledPath
        $getColor={getColor}
        d="M8.00065 14.6693C11.6825 14.6693 14.6673 11.6845 14.6673 8.0026C14.6673 4.32071 11.6825 1.33594 8.00065 1.33594C4.31875 1.33594 1.33398 4.32071 1.33398 8.0026C1.33398 11.6845 4.31875 14.6693 8.00065 14.6693Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
      <StyledPath
        $getColor={getColor}
        d="M5.33398 8H10.6673"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.33333"
      />
    </g>
    <defs>
      <clipPath id="clip0_8415_51183">
        <StyledRect $getColor={getColor} height={size} width={size} />
      </clipPath>
    </defs>
  </svg>
);

export default MinusCircleIcon;
