import styled from "styled-components";

import type {GetColor, GetColorStyledProps} from "./types";

type Props = {
  className?: string;
  getColor: GetColor<GetColorStyledProps>;
  size: number;
};

const StyledPath = styled.path<GetColorStyledProps>`
  fill: ${props => props.$getColor};
`;

const FilledInfoIcon = ({getColor, size, ...rest}: Props) => (
  <svg fill="none" height={size} viewBox="0 0 16 16" width={size} {...rest}>
    <StyledPath
      $getColor={getColor}
      d="M8.789 5.596V3.981H7.21v1.615H8.79zm0 6.423V7.21H7.21v4.808H8.79zm-6.46-9.653C3.906.79 5.797 0 8 0c2.203 0 4.081.789 5.634 2.366C15.21 3.92 16 5.796 16 8c0 2.203-.789 4.094-2.366 5.671C12.08 15.224 10.204 16 8 16c-2.203 0-4.094-.776-5.671-2.329C.776 12.094 0 10.203 0 8c0-2.203.776-4.081 2.329-5.634z"
    />
  </svg>
);

export default FilledInfoIcon;
