import styled from "styled-components";

import type {GetColor, GetColorStyledProps} from "./types";

type Props = {
  className?: string;
  getColor: GetColor<GetColorStyledProps>;
  size: number;
};

const StyledPath = styled.path<GetColorStyledProps>`
  fill: ${props => props.$getColor};
`;

const FilledCheckIcon = ({getColor, size, ...rest}: Props) => (
  <svg fill="none" height={size} viewBox="0 0 16 16" width={size} {...rest}>
    <StyledPath
      $getColor={getColor}
      clipRule="evenodd"
      d="M8 16A8 8 0 108 0a8 8 0 000 16zm4.307-9.293a1 1 0 00-1.414-1.414L7.2 8.986 5.907 7.693a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4.4-4.4z"
      fillRule="evenodd"
    />
  </svg>
);

export default FilledCheckIcon;
