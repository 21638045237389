import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

const StyledPath = styled.path`
  stroke: ${getColor("blue", "700")};
`;

const MenuDotIcon = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      d="M7.99967 8.66732C8.36786 8.66732 8.66634 8.36884 8.66634 8.00065C8.66634 7.63246 8.36786 7.33398 7.99967 7.33398C7.63148 7.33398 7.33301 7.63246 7.33301 8.00065C7.33301 8.36884 7.63148 8.66732 7.99967 8.66732Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.17876"
    />
    <StyledPath
      d="M7.99967 3.99935C8.36786 3.99935 8.66634 3.70087 8.66634 3.33268C8.66634 2.96449 8.36786 2.66602 7.99967 2.66602C7.63148 2.66602 7.33301 2.96449 7.33301 3.33268C7.33301 3.70087 7.63148 3.99935 7.99967 3.99935Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.17876"
    />
    <StyledPath
      d="M7.99967 13.3333C8.36786 13.3333 8.66634 13.0349 8.66634 12.6667C8.66634 12.2985 8.36786 12 7.99967 12C7.63148 12 7.33301 12.2985 7.33301 12.6667C7.33301 13.0349 7.63148 13.3333 7.99967 13.3333Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.17876"
    />
  </svg>
);

export default MenuDotIcon;
