import styled from "styled-components";

import type {GetColor, GetColorStyledProps} from "./types";

type Props = {
  getColor: GetColor<GetColorStyledProps>;
  size: number;
};

const StyledPath = styled.path<GetColorStyledProps>`
  fill: ${props => props.$getColor};
`;

const FlagIcon = ({getColor, size}: Props) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      $getColor={getColor}
      d="M12.1614 10.2828C12.3228 10.4003 12.5312 10.4157 12.7083 10.3338C12.8854 10.247 13 10.068 13 9.87393V4.25266C13 4.09427 12.9219 3.94088 12.7916 3.84386C9.54457 1.44179 6.3304 4.99006 3.625 4.42126V11.0799C6.8087 11.5374 9.52155 8.31996 12.1614 10.2828Z"
    />
    <StyledPath
      $getColor={getColor}
      d="M3 3.4948V14.3802C3 14.6537 3.27952 14.875 3.625 14.875C3.97048 14.875 4.25 14.6537 4.25 14.3802L4.24989 3.4948C4.24989 3.22129 3.97037 3 3.62489 3C3.27952 3 3 3.22129 3 3.4948Z"
    />
  </svg>
);

export default FlagIcon;
