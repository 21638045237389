import styled from "styled-components";

import type {GetColor, GetColorStyledProps} from "./types";

type Props = {
  className?: string;
  getColor: GetColor<GetColorStyledProps>;
  size: number;
};

const StyledPath = styled.path<GetColorStyledProps>`
  fill: ${props => props.$getColor};
`;

const PlusIcon = ({getColor, size, ...rest}: Props) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 12 12"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      $getColor={getColor}
      clipRule="evenodd"
      d="M6.59909 1.33437C6.59909 1.003 6.33046 0.734375 5.99909 0.734375C5.66772 0.734375 5.39909 1.003 5.39909 1.33437V5.40103H1.33242C1.00105 5.40103 0.732422 5.66966 0.732422 6.00103C0.732422 6.3324 1.00105 6.60103 1.33242 6.60103H5.39909V10.6677C5.39909 10.9991 5.66772 11.2677 5.99909 11.2677C6.33046 11.2677 6.59909 10.9991 6.59909 10.6677V6.60103H10.6658C10.9971 6.60103 11.2658 6.3324 11.2658 6.00103C11.2658 5.66966 10.9971 5.40103 10.6658 5.40103H6.59909V1.33437Z"
      fillRule="evenodd"
      {...rest}
    />
  </svg>
);

export default PlusIcon;
