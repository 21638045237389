import {getColorByAlias} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

const StyledPath = styled.path`
  stroke: ${getColorByAlias("backgroundWhite")};
`;

const CheckCircleIcon = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      d="M12.5 22C18.0228 22 22.5 17.5228 22.5 12C22.5 6.47715 18.0228 2 12.5 2C6.97715 2 2.5 6.47715 2.5 12C2.5 17.5228 6.97715 22 12.5 22Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <StyledPath
      d="M17 9L11.5 16.01L8.5 13.01"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
);

export default CheckCircleIcon;
