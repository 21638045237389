import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  size: number;
};

const StyledPath = styled.path`
  stroke: ${getColor("gray", "000")};
`;

const PowerIcon = ({size, ...rest}: Props) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 16 16"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <StyledPath
      d="M12.24 4.43a6 6 0 11-8.487 0M8 1.336v6.667"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.4122}
    />
  </svg>
);

export default PowerIcon;
