import * as withTheme from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  size: number;
};

const StyledG = styled.g`
  stroke: ${withTheme.getColor("blue", "700")};
`;

const StyledPath = styled.path`
  fill: ${withTheme.getColor("gray", "000")};
`;

const PaperAirplaneIcon = ({size, ...rest}: Props) => (
  <svg {...rest} fill="none" height={size} viewBox="0 0 16 16" width={size}>
    <StyledG
      clipPath="url(#clip0_10112_177654)"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.33333}
    >
      <path d="M14.665 1.333L7.332 8.666M14.665 1.333L10 14.666l-2.667-6-6-2.666 13.333-4.667z" />
    </StyledG>
    <defs>
      <clipPath id="clip0_10112_177654">
        <StyledPath d="M0 0H16V16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default PaperAirplaneIcon;
