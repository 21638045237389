import {getColorByAlias} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  size?: number;
};

const StyledPath = styled.path`
  stroke: ${getColorByAlias("accentSecondary")};
`;

const DownloadIcon = ({size, ...rest}: Props) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 16 16"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <StyledPath
      d="M14 9V11.6667C14 12.0203 13.8595 12.3594 13.6095 12.6095C13.3594 12.8595 13.0203 13 12.6667 13H3.33333C2.97971 13 2.64057 12.8595 2.39052 12.6095C2.14048 12.3594 2 12.0203 2 11.6667V9"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <StyledPath
      d="M4.66732 6.66667L8.00065 10L11.334 6.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
    <StyledPath
      d="M8 10L8 2"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.4"
    />
  </svg>
);

export default DownloadIcon;
