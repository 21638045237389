import {
  getColor,
  getColorByAlias
} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  isSelected?: boolean;
  size: number;
};

type StyledPathStyledProps = {
  $isSelected?: boolean;
};

type StyledRectStyledProps = {
  $isSelected?: boolean;
};

const StyledPath = styled.path<StyledPathStyledProps>`
  stroke: ${props =>
    props.$isSelected
      ? getColor("gray", "000")
      : getColorByAlias("accentSecondary")};
`;

const StyledRect = styled.rect<StyledRectStyledProps>`
  fill: ${props =>
    props.$isSelected
      ? getColorByAlias("accentSecondary")
      : getColor("gray", "000")};
`;

const EyeIcon = ({isSelected, size, ...rest}: Props) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 16 16"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <g clipPath="url(#clip0_10442_3495)">
      <StyledPath
        $isSelected={isSelected}
        d="M0.667969 8.00008C0.667969 8.00008 3.33464 2.66675 8.0013 2.66675C12.668 2.66675 15.3346 8.00008 15.3346 8.00008C15.3346 8.00008 12.668 13.3334 8.0013 13.3334C3.33464 13.3334 0.667969 8.00008 0.667969 8.00008Z"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.3595"
      />
      <StyledPath
        $isSelected={isSelected}
        d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="1.3595"
      />
    </g>
    <defs>
      <clipPath id="clip0_10442_3495">
        <StyledRect $isSelected={isSelected} height="16" width="16" />
      </clipPath>
    </defs>
  </svg>
);

export default EyeIcon;
