import {getColorByAlias} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  size: number;
};

const StyledPath = styled.path`
  stroke: ${getColorByAlias("accentSecondary")};
`;

const CloseIcon = ({size}: Props) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      d="M12.0001 3.9267L3.92676 12"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.0784"
    />
    <StyledPath
      d="M3.92676 3.9267L12.0001 12"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.0784"
    />
  </svg>
);

export default CloseIcon;
