import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  size: number;
};

const RedPath = styled.path`
  fill: ${getColor("red", "500")};
`;

const PauseIcon = ({size, ...rest}: Props) => (
  <svg
    fill="none"
    height={`${size}px`}
    viewBox={`0 0 ${size} ${size}`}
    width={`${size}px`}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <RedPath d="M6.66667 2.66406H4V13.3307H6.66667V2.66406Z" />
    <RedPath d="M11.9987 2.66406H9.33203V13.3307H11.9987V2.66406Z" />
  </svg>
);

export default PauseIcon;
