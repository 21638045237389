import {getColorByAlias} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  height?: string;
  width?: string;
};

const StyledPath = styled.path`
  stroke: ${getColorByAlias("accentSecondary")};
`;

const ArrowLinkIcon = ({height = "16", width = "16", ...rest}: Props) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 16 16"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <StyledPath
      d="M4.66602 11.3327L11.3327 4.66602"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.33333"
    />
    <StyledPath
      d="M4.66602 4.66602H11.3327V11.3327"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.33333"
    />
  </svg>
);

export default ArrowLinkIcon;
