import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

const BlueCircle = styled.circle`
  fill: ${getColor("blue", "500")};
`;

const BlueEllipse = styled.ellipse`
  fill: ${getColor("blue", "500")};
`;

const LightBlueCircle = styled.circle`
  fill: ${getColor("blue", "400")};
`;

const OrangeCircle = styled.circle`
  fill: ${getColor("orange", "500")};
`;

const BuyerAvatarIcon = () => (
  <svg
    fill="none"
    height="24"
    viewBox="0 0 24 24"
    width="24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z" />
    <mask
      height="24"
      id="mask0_2662_30681"
      maskUnits="userSpaceOnUse"
      width="24"
      x="0"
      y="0"
    >
      <OrangeCircle cx="12" cy="12" r="12" />
    </mask>
    <g mask="url(#mask0_2662_30681)">
      <LightBlueCircle cx="12" cy="12" r="12" />
      <BlueEllipse
        cx="12"
        cy="8.57143"
        opacity="0.5"
        rx="4.57143"
        ry="4.57143"
      />
      <BlueCircle cx="12" cy="26.8571" opacity="0.5" r="10.2857" />
    </g>
  </svg>
);

export default BuyerAvatarIcon;
