type Props = {
  className?: string;
  height?: string;
  width?: string;
};

const TooltipInfoIcon = ({height = "16", width = "16", ...rest}: Props) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 16 16"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      d="M7.34375 6V4.65625H8.65625V6H7.34375ZM4.21875 11.7812C5.28125 12.8229 6.54167 13.3438 8 13.3438C9.45833 13.3438 10.7083 12.8229 11.75 11.7812C12.8125 10.7188 13.3438 9.45833 13.3438 8C13.3438 6.54167 12.8125 5.29167 11.75 4.25C10.7083 3.1875 9.45833 2.65625 8 2.65625C6.54167 2.65625 5.28125 3.1875 4.21875 4.25C3.17708 5.29167 2.65625 6.54167 2.65625 8C2.65625 9.45833 3.17708 10.7188 4.21875 11.7812ZM3.28125 3.3125C4.59375 2 6.16667 1.34375 8 1.34375C9.83333 1.34375 11.3958 2 12.6875 3.3125C14 4.60417 14.6562 6.16667 14.6562 8C14.6562 9.83333 14 11.4062 12.6875 12.7188C11.3958 14.0104 9.83333 14.6562 8 14.6562C6.16667 14.6562 4.59375 14.0104 3.28125 12.7188C1.98958 11.4062 1.34375 9.83333 1.34375 8C1.34375 6.16667 1.98958 4.60417 3.28125 3.3125ZM7.34375 11.3438V7.34375H8.65625V11.3438H7.34375Z"
      fill="#BFBFBF"
    />
  </svg>
);

export default TooltipInfoIcon;
