import * as withTheme from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

import type {GetColor, GetColorStyledProps} from "./types";

type Props = {
  className?: string;
  getColor: GetColor<GetColorStyledProps>;
  size: number;
};

const StyledG = styled.g<GetColorStyledProps>`
  stroke: ${props => props.$getColor};
`;

const StyledPath = styled.path`
  fill: ${withTheme.getColor("gray", "000")};
`;

const OverwriteIcon = ({getColor, size, ...rest}: Props) => (
  <svg fill="none" height={size} viewBox="0 0 16 16" width={size} {...rest}>
    <StyledG
      $getColor={getColor}
      clipPath="url(#clip0_10646_234490)"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path
        d="M13.461 5H6.538C5.688 5 5 5.689 5 6.538v6.923C5 14.311 5.689 15 6.538 15h6.923c.85 0 1.539-.689 1.539-1.539V6.538C15 5.688 14.311 5 13.461 5zM2.385 7h-.462A.923.923 0 011 6.077V1.923A.923.923 0 011.923 1h4.154A.923.923 0 017 1.923v.462"
        strokeWidth={1.33333}
      />
      <g clipPath="url(#clip1_10646_234490)" strokeWidth={0.666667}>
        <path d="M11.46 6.792l1.168 1.167-1.167 1.166" />
        <path d="M7.375 9.709v-.583a1.167 1.167 0 011.167-1.167h4.083M8.542 13.208l-1.167-1.166 1.167-1.167" />
        <path d="M12.625 10.292v.583a1.167 1.167 0 01-1.167 1.167H7.375" />
      </g>
    </StyledG>
    <defs>
      <clipPath id="clip0_10646_234490">
        <StyledPath d="M0 0H16V16H0z" />
      </clipPath>
      <clipPath id="clip1_10646_234490">
        <StyledPath d="M0 0H7V7H0z" transform="translate(6.5 6.5)" />
      </clipPath>
    </defs>
  </svg>
);

export default OverwriteIcon;
