import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled, {css} from "styled-components";

type Direction = "down" | "left" | "right" | "up";

type StyledSvgStyledProps = {
  $direction: Direction;
};

type Props = {
  className?: string;
  direction: Direction;
  height: number;
};

const StyledPath = styled.path`
  fill: ${getColor("blue", "700")};
`;

const viewBoxHeight = 20;

const viewBoxWidth = 12;

const viewBox = `0 0 ${viewBoxWidth} ${viewBoxHeight}`;

const widthVsHeight = viewBoxWidth / viewBoxHeight;

const getWidth = (height: number) => height * widthVsHeight;

const StyledSvg = styled.svg<StyledSvgStyledProps>`
  ${props => {
    switch (props.$direction) {
      case "down":
        return css`
          transform: rotate(90deg);
        `;

      case "left":
        return css`
          transform: rotate(180deg);
        `;

      case "up":
        return css`
          transform: rotate(270deg);
        `;

      case "right":
        return;
    }
  }};
`;

const ChevronIcon = ({direction, height, ...rest}: Props) => (
  <StyledSvg
    {...rest}
    $direction={direction}
    fill="none"
    height={height}
    viewBox={viewBox}
    width={getWidth(height)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      clip-rule="evenodd"
      d="M0.93934 0.93934C1.52513 0.353553 2.47487 0.353553 3.06066 0.93934L11.0607 8.93934C11.6464 9.52513 11.6464 10.4749 11.0607 11.0607L3.06066 19.0607C2.47487 19.6464 1.52513 19.6464 0.939341 19.0607C0.353554 18.4749 0.353554 17.5251 0.93934 16.9393L7.87868 10L0.93934 3.06066C0.353553 2.47487 0.353553 1.52513 0.93934 0.93934Z"
      fill-rule="evenodd"
    />
  </StyledSvg>
);

export default ChevronIcon;
