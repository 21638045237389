import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  size: number;
};

const GreenPath = styled.path`
  fill: ${getColor("green", "500")};
  stroke: ${getColor("green", "500")};
`;

const PlayIcon = ({size, ...rest}: Props) => (
  <svg
    fill="none"
    height={`${size}px`}
    viewBox={`0 0 ${size} ${size}`}
    width={`${size}px`}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <GreenPath
      d="M6.41797 5L10.9154 7.99828L6.41797 10.9966V5Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2.5"
    />
  </svg>
);

export default PlayIcon;
