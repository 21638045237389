import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

const StyledPath = styled.path`
  fill: ${getColor("gray", "900")};
`;

const CheckIcon = () => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      clipRule="evenodd"
      d="M13.7225 3.28243C14.0925 3.65901 14.0925 4.26956 13.7225 4.64614L6.77515 11.7176C6.40518 12.0941 5.80534 12.0941 5.43537 11.7176L2.27748 8.50328C1.90751 8.1267 1.90751 7.51615 2.27748 7.13958C2.64745 6.763 3.24729 6.763 3.61726 7.13958L6.10526 9.67201L12.3827 3.28243C12.7527 2.90586 13.3526 2.90586 13.7225 3.28243Z"
      fillRule="evenodd"
    />
  </svg>
);

export default CheckIcon;
