import styled from "styled-components";

import type {GetColor, GetColorStyledProps} from "./types";

type Props = {
  getColor: GetColor<GetColorStyledProps>;
  size: number;
};

const StyledPath = styled.path<GetColorStyledProps>`
  stroke: ${props => props.$getColor};
`;

const MultipleUsersIcon = ({getColor, size}: Props) => (
  <svg
    fill="none"
    height={size}
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      $getColor={getColor}
      d="M11.3327 14V12.6667C11.3327 11.9594 11.0517 11.2811 10.5516 10.781C10.0515 10.281 9.37326 10 8.66602 10H3.33268C2.62544 10 1.94716 10.281 1.44706 10.781C0.946967 11.2811 0.666016 11.9594 0.666016 12.6667V14"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
    <StyledPath
      $getColor={getColor}
      d="M6.00065 7.33333C7.47341 7.33333 8.66732 6.13943 8.66732 4.66667C8.66732 3.19391 7.47341 2 6.00065 2C4.52789 2 3.33398 3.19391 3.33398 4.66667C3.33398 6.13943 4.52789 7.33333 6.00065 7.33333Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
    <StyledPath
      $getColor={getColor}
      d="M15.334 14.0002V12.6669C15.3335 12.0761 15.1369 11.5021 14.7749 11.0351C14.4129 10.5682 13.9061 10.2346 13.334 10.0869"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
    <StyledPath
      $getColor={getColor}
      d="M10.666 2.08691C11.2396 2.23378 11.748 2.56738 12.1111 3.03512C12.4742 3.50286 12.6712 4.07813 12.6712 4.67025C12.6712 5.26236 12.4742 5.83763 12.1111 6.30537C11.748 6.77311 11.2396 7.10671 10.666 7.25358"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
  </svg>
);

export default MultipleUsersIcon;
