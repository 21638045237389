type Props = {
  height: number;
};

const viewBoxHeight = 68;

const viewBoxWidth = 84;

const viewBox = `0 0 ${viewBoxWidth} ${viewBoxHeight}`;

const widthVsHeight = viewBoxWidth / viewBoxHeight;

const getWidth = (height: number) => height * widthVsHeight;

const CameraIcon = ({height}: Props) => (
  <svg
    {...{height, viewBox}}
    fill="none"
    width={getWidth(height)}
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      clipRule="evenodd"
      d="M80.8981 65.787C82.3151 64.3701 83.1111 62.4483 83.1111 60.4444V18.8889C83.1111 16.885 82.3151 14.9632 80.8981 13.5463C79.4812 12.1294 77.5594 11.3333 75.5556 11.3333H60.4444L52.8889 0H30.2222L22.6667 11.3333H7.55556C5.5517 11.3333 3.62991 12.1294 2.21297 13.5463C0.79603 14.9632 0 16.885 0 18.8889V60.4444C0 62.4483 0.79603 64.3701 2.21297 65.787C3.62991 67.204 5.5517 68 7.55556 68H75.5556C77.5594 68 79.4812 67.204 80.8981 65.787ZM28.9039 37.7752C28.9039 30.7884 34.5677 25.1246 41.5545 25.1246C48.5412 25.1246 54.2051 30.7884 54.2051 37.7752C54.2051 44.7619 48.5412 50.4258 41.5545 50.4258C34.5677 50.4258 28.9039 44.7619 28.9039 37.7752ZM41.5545 20.2035C31.8499 20.2035 23.9828 28.0706 23.9828 37.7752C23.9828 47.4797 31.8499 55.3468 41.5545 55.3468C51.259 55.3468 59.1261 47.4797 59.1261 37.7752C59.1261 28.0706 51.259 20.2035 41.5545 20.2035Z"
      fill="white"
      fillRule="evenodd"
      opacity="0.8"
    />
  </svg>
);

export default CameraIcon;
