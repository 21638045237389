import {getColorByAlias} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

const StyledPath = styled.path`
  fill: ${getColorByAlias("accentPrimary")};
`;

const ErrorIcon = () => (
  <svg
    fill="none"
    height="80"
    viewBox="0 0 80 80"
    width="80"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath d="M39.9994 6.66667C21.6204 6.66667 6.66602 21.6211 6.66602 40C6.66602 45.4557 8.10482 50.5599 10.4355 55.1042L6.82227 68.0469C5.98242 71.0547 8.95117 74.0235 11.959 73.1771L24.9017 69.5638C29.4525 71.8945 34.5436 73.3334 39.9994 73.3334C58.3783 73.3334 73.3327 58.3789 73.3327 40C73.3327 21.6211 58.3783 6.66667 39.9994 6.66667ZM39.9994 11.6667C55.6764 11.6667 68.3327 24.3229 68.3327 40C68.3327 55.6771 55.6764 68.3334 39.9994 68.3334C34.9928 68.3334 30.3184 67.0313 26.2428 64.7591C25.6634 64.4336 24.9863 64.3555 24.3483 64.5313L12.0371 67.9688L15.4746 55.6641C15.6504 55.0261 15.5723 54.349 15.2467 53.7761C12.9746 49.6875 11.666 45.0065 11.666 40C11.666 24.3229 24.3223 11.6667 39.9994 11.6667ZM39.9603 21.6341C38.5801 21.6537 37.4798 22.7865 37.4994 24.1667V44.1667C37.4863 45.0651 37.9616 45.905 38.7363 46.3607C39.5176 46.8164 40.4811 46.8164 41.2624 46.3607C42.0371 45.905 42.5124 45.0651 42.4994 44.1667V24.1667C42.5059 23.4896 42.2454 22.8385 41.7637 22.3633C41.2884 21.8815 40.6374 21.6211 39.9603 21.6341ZM39.9994 51.6667C38.1569 51.6667 36.666 53.1576 36.666 55C36.666 56.8425 38.1569 58.3333 39.9994 58.3333C41.8418 58.3333 43.3327 56.8425 43.3327 55C43.3327 53.1576 41.8418 51.6667 39.9994 51.6667Z" />
  </svg>
);

export default ErrorIcon;
