import {getColorByAlias} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

const StyledPath = styled.path`
  stroke: ${getColorByAlias("backgroundWhite")};
`;

const CalendarEmptyIcon = () => (
  <svg
    fill="none"
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      d="M15.8333 3.33301H4.16667C3.24619 3.33301 2.5 4.0792 2.5 4.99967V16.6663C2.5 17.5868 3.24619 18.333 4.16667 18.333H15.8333C16.7538 18.333 17.5 17.5868 17.5 16.6663V4.99967C17.5 4.0792 16.7538 3.33301 15.8333 3.33301Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.66667"
    />
    <StyledPath
      d="M13.333 1.66699V5.00033"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.66667"
    />
    <StyledPath
      d="M6.66699 1.66699V5.00033"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.66667"
    />
    <StyledPath
      d="M2.5 8.33301H17.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.66667"
    />
  </svg>
);

export default CalendarEmptyIcon;
