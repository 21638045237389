import {getColorByAlias} from "@unlockre/components-library/dist/theme-provider/theme";
import styled, {css} from "styled-components";

type BluePathStyledProps = {
  $isFilled?: boolean;
};

type Props = {
  isFilled?: boolean;
};

const filledCss = css`
  fill: ${getColorByAlias("accentPrimary")};
`;

const BluePath = styled.path<BluePathStyledProps>`
  ${props => props.$isFilled && filledCss}
  stroke: ${getColorByAlias("accentPrimary")};
`;

const FolderIcon = ({isFilled}: Props) => (
  <svg
    fill="none"
    height="16"
    viewBox="0 0 16 16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <BluePath
      $isFilled={isFilled}
      d="M14.6693 13.1667C14.6693 13.5203 14.5288 13.8594 14.2787 14.1095C14.0287 14.3595 13.6896 14.5 13.3359 14.5H2.66927C2.31565 14.5 1.97651 14.3595 1.72646 14.1095C1.47641 13.8594 1.33594 13.5203 1.33594 13.1667V3.83333C1.33594 3.47971 1.47641 3.14057 1.72646 2.89052C1.97651 2.64048 2.31565 2.5 2.66927 2.5H6.0026L7.33594 4.5H13.3359C13.6896 4.5 14.0287 4.64048 14.2787 4.89052C14.5288 5.14057 14.6693 5.47971 14.6693 5.83333V13.1667Z"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.33333"
    />
  </svg>
);

export default FolderIcon;
