import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  size: number;
};

const StyledPath = styled.path`
  fill: ${getColor("blue", "700")};
`;

const ArrowBackIcon = ({size, ...rest}: Props) => (
  <svg
    {...rest}
    fill="none"
    height={size}
    viewBox="0 0 16 16"
    width={size}
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath d="M13.6797 6.17969V7.82031H3.52344L8.17188 12.5078L7 13.6797L0.320312 7L7 0.320312L8.17188 1.49219L3.52344 6.17969H13.6797Z" />
  </svg>
);

export default ArrowBackIcon;
