import styled from "styled-components";

import type {GetColor, GetColorStyledProps} from "./types";

type Props = {
  className?: string;
  getColor: GetColor<GetColorStyledProps>;
  size: number;
};

const StyledPath = styled.path<GetColorStyledProps>`
  stroke: ${props => props.$getColor};
`;

const WarningIcon = ({getColor, size, ...rest}: Props) => (
  <svg fill="none" height={size} viewBox="0 0 16 16" width={size} {...rest}>
    <StyledPath
      $getColor={getColor}
      d="M6.86 2.575l-5.646 9.427a1.333 1.333 0 001.14 2h11.293a1.333 1.333 0 001.14-2L9.14 2.575a1.333 1.333 0 00-2.28 0zM8 6v2.667M8 11.332h.008"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1.33333}
    />
  </svg>
);

export default WarningIcon;
