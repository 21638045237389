const MemberIcon = () => (
  <svg
    fill="none"
    height="32"
    viewBox="0 0 32 32"
    width="32"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.6475 16.203C22.0093 16.203 24.7461 13.4662 24.7461 10.1044C24.7458 6.74252 22.0093 4 18.6475 4C15.2856 4 12.5488 6.73679 12.5488 10.0986C12.5488 13.4605 15.2856 16.203 18.6475 16.203ZM18.6475 5.76932C21.0362 5.76932 22.9767 7.70985 22.9767 10.0986C22.9767 12.4873 21.0362 14.4278 18.6475 14.4278C16.2587 14.4278 14.3182 12.4873 14.3182 10.0986C14.3182 7.70985 16.2587 5.76932 18.6475 5.76932Z"
      fill="currentColor"
    />
    <path
      d="M9.15731 28.1832H27.667C28.1564 28.1832 28.5517 27.788 28.5517 27.2985C28.5517 26.809 28.1564 26.4138 27.667 26.4138H10.0895C10.5319 22.0846 14.2062 18.6932 18.6473 18.6932C23.3894 18.6932 27.2523 22.5564 27.2523 27.2982C27.2523 27.7877 27.6475 28.1829 28.137 28.1829C28.6264 28.1829 29.0217 27.7877 29.0217 27.2982C29.0217 21.5772 24.3681 16.9238 18.6473 16.9238C12.9265 16.9238 8.27295 21.5774 8.27295 27.2982C8.27295 27.7877 8.66817 28.1829 9.15764 28.1829L9.15731 28.1832Z"
      fill="currentColor"
    />
    <path
      d="M3.88496 16.9743H6.23835V19.3277C6.23835 19.8171 6.63356 20.2124 7.12304 20.2124C7.61252 20.2124 8.00774 19.8171 8.00774 19.3277L8.00747 16.9743H10.3609C10.8503 16.9743 11.2456 16.5791 11.2456 16.0896C11.2456 15.6001 10.8503 15.2049 10.3609 15.2049H8.00747V12.8515C8.00747 12.362 7.61225 11.9668 7.12278 11.9668C6.6333 11.9668 6.23808 12.362 6.23808 12.8515V15.2049H3.88469C3.39522 15.2049 3 15.6001 3 16.0896C3.00026 16.5791 3.39522 16.9743 3.88496 16.9743H3.88496Z"
      fill="currentColor"
    />
  </svg>
);

export default MemberIcon;
