import {getColorByAlias} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
};

const StyledPath = styled.path`
  stroke: ${getColorByAlias("accentSecondary")};
`;

const UnlinkIcon = (props: Props) => (
  <svg
    {...props}
    fill="none"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <StyledPath
      d="M7.77125 9.27079C7.97806 9.62705 8.25907 9.93467 8.59523 10.1728C8.93138 10.4109 9.31481 10.5739 9.71951 10.6508C10.1242 10.7277 10.5407 10.7167 10.9408 10.6184C11.3408 10.5202 11.7151 10.3371 12.0382 10.0815L13.9502 8.56944C14.5328 8.09199 14.9046 7.40481 14.9854 6.6559C15.0663 5.90699 14.8498 5.15628 14.3826 4.56545C13.9153 3.97462 13.2347 3.59094 12.4873 3.49706C11.7399 3.40318 10.9856 3.6066 10.3867 4.06352L9.29116 4.92473"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <StyledPath
      d="M6.95379 7.17588C6.64582 6.9023 6.2826 6.69815 5.88879 6.57729C5.49498 6.45642 5.07977 6.42167 4.67135 6.47538C4.26292 6.52909 3.87083 6.67001 3.52165 6.88859C3.17248 7.10716 2.87439 7.39827 2.64762 7.74217L1.30533 9.77695C0.901532 10.4128 0.763645 11.1819 0.921364 11.9185C1.07908 12.655 1.51979 13.3002 2.14857 13.715C2.77734 14.1297 3.54387 14.281 4.28307 14.1361C5.02227 13.9912 5.67498 13.5618 6.10063 12.9403L6.86573 11.7805"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="1.5"
    />
    <StyledPath
      d="M7.8125 3.35156L8.08327 2.04601"
      strokeLinecap="round"
      strokeWidth="0.75"
    />
    <StyledPath
      d="M6.50781 3.08203L5.99042 2.29387"
      strokeLinecap="round"
      strokeWidth="0.75"
    />
    <StyledPath
      d="M6.23654 4.38932L4.99869 3.79215"
      strokeLinecap="round"
      strokeWidth="0.75"
    />
  </svg>
);

export default UnlinkIcon;
