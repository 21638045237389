import {getColorByAlias} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  size: number;
};

const StyledPath = styled.path`
  fill: ${getColorByAlias("accentTertiary")};
`;

const InfoIcon = ({size, ...rest}: Props) => (
  <svg fill="none" height={size} viewBox="0 0 46 46" width={size} {...rest}>
    <StyledPath
      clipRule="evenodd"
      d="M45.4 23.002a22.4 22.4 0 11-44.801 0 22.4 22.4 0 0144.8 0zm-19.6-11.2a2.8 2.8 0 11-5.6 0 2.8 2.8 0 015.6 0zm-5.6 8.4a2.8 2.8 0 000 5.6v8.4a2.8 2.8 0 002.8 2.8h2.8a2.8 2.8 0 000-5.6v-8.4a2.8 2.8 0 00-2.8-2.8h-2.8z"
      fillRule="evenodd"
    />
  </svg>
);

export default InfoIcon;
