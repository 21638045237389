import {getColor} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  size: number;
};

const StyledPath = styled.path`
  fill: ${getColor("gray", "000")};
`;

const KeywayIcon = ({size, ...rest}: Props) => (
  <svg fill="none" height={size} viewBox="0 0 86 85" width={size} {...rest}>
    <StyledPath
      clipRule="evenodd"
      d="M.222.218h84.782V85H.222V.218zM6.743 6.74v67.127L73.87 6.74H6.743zm71.739 4.611L50.485 39.348h27.997V11.351zm0 34.519H50.485l27.997 27.997V45.87zM73.87 78.478L42.613 47.221 11.355 78.478H73.87z"
      fillRule="evenodd"
    />
  </svg>
);

export default KeywayIcon;
