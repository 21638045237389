import {getColorByAlias} from "@unlockre/components-library/dist/theme-provider/theme";
import styled from "styled-components";

type Props = {
  className?: string;
  height?: string;
  width?: string;
};

const StyledPath = styled.path`
  fill: ${getColorByAlias("textPrimary")};
`;

const ArrowUpIcon = ({height = "18", width = "18", ...rest}: Props) => (
  <svg
    fill="none"
    height={height}
    viewBox="0 0 14 12"
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <StyledPath d="M8.82812 6.85156L5 3.02344L1.17187 6.85156L-7.27385e-07 5.67969L5 0.679688L10 5.67969L8.82812 6.85156Z" />
  </svg>
);

export default ArrowUpIcon;
